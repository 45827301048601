import React, { useContext } from 'react';

import { ASSETS_URL } from '../../constants';
import { ContentContext } from 'contexts/ContentContext';

import {
  HomeHeader,
  Section,
  Footer,
  RecipeList,
  StoreLocator,
  Spinner
} from 'components';

import cooking01Img from 'assets/images/cooking-01.jpg';
import adBannerImg from 'assets/images/ad-banner.png';
import truckImg from 'assets/images/icons/truck.svg';
import storeImg from 'assets/images/icons/store.svg';
import productImg from 'assets/images/icons/product.svg';

import styles from './Home.module.scss';

const Home: React.FC = () => {
  const { productCategories, recipes, isLoading } = useContext(ContentContext);

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  return (
    <>
      <HomeHeader />

      <Section id='categories' className={styles['categories']}>
        <h2 className={styles['categories__heading']}>Product categories</h2>

        <ul className={styles['categories__list']}>
          {productCategories?.map(category => (
            <li key={category.id} className={styles['categories__list-item']}>
              <a href={`/products?category=${category.name}`} className='display--flex'>
                <img src={ASSETS_URL + category.feature_image} alt={category.name} />
                <h3>{category.name}</h3>
              </a>
            </li>
          ))}
        </ul>

        <div className='button-group text--center'>
          <a className='button button--primary' href='/products'>View all Products</a>
          <a className='button' href='/recipes'>Healthy Recipes</a>
        </div>
      </Section>

      <Section id='mission' className={styles['mission']}>
        <div className='halfWidth'>
          <h2 className={styles['mission__heading']}>Our Mission</h2>
          <p className='font--large margin--none'>To continue to craft products that evoke that homemade taste, under a brand that&apos;s been loved and trusted for over 30 years.</p>
        </div>

        <img className={styles['mission__image']} src={cooking01Img} alt='Street food' />
      </Section>

      <Section id='wholesalers' className={styles['wholesales']}>
        <div className={styles['wholesales__container']}>
          <h2 className={styles['wholesales__heading']}>Wholesales</h2>
          <p className='font--large margin--none'>Evergreen is Australia&apos;s favourite and most trusted producer of Asian food products. Get in touch if you would like to work with us.</p>
        </div>

        <ul className={`${styles['wholesales__list']} font--large`}>
          <li className={styles['wholesales__list-item']}>
            <img src={truckImg} alt='Wholesaler' />
            <a href='/wholesales'>Wholesaler details</a>
          </li>
          <li className={styles['wholesales__list-item']}>
            <img src={storeImg} alt='Stores' />
            <a href='#store-locator'>Store locations</a>
          </li>
          <li className={styles['wholesales__list-item']}>
            <img src={productImg} alt='Products' />
            <a href='/products'>Products</a>
          </li>
        </ul>

        <StoreLocator
          style={{
            zIndex: 10,
            position: 'absolute',
            transform: 'translateX(-50%)',
            left: '50%',
            bottom: '-20%'
          }} />
      </Section>

      <Section id='recipes' className={styles['recipes']}>
        <RecipeList recipes={recipes} />

        <div className='button-group text--center'>
          <a className='button button--primary' href='/recipes'>
            View all Recipes
          </a>
        </div>

        <img className={styles['recipes__banner']} src={adBannerImg} alt='Now at Woolworths' />
      </Section>

      <Footer />
    </>
  );
};

export default Home;
