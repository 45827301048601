import React, { useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

import { ASSETS_URL } from '../../constants';
import { ContentContext } from 'contexts/ContentContext';
import { IProduct } from 'types/directus';

import {
  NavigationBar,
  Section,
  Footer,
  StoreLocator,
  NutritionModal,
  Spinner
} from 'components';

import styles from './Products.module.scss';

const Products: React.FC = () => {
  const [search] = useSearchParams();
  const { productCategories, products, isLoading } = useContext(ContentContext);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);

  const isMobile = useMediaQuery({ query: '(max-width: 764px)' });

  useEffect(() => {
    // Select category based on search param
    const categoryQueryParam = search.get('category');
    if (categoryQueryParam && productCategories) {
      const searchCategory = productCategories.find(category => category.name?.toLowerCase() === categoryQueryParam.toLowerCase());

      if (searchCategory) {
        setSelectedCategoryId(searchCategory.id);
      }
    }
  }, [products, search]);

  let filteredProducts = [...products || []];

  // Filter products if a category is selected
  if (selectedCategoryId !== null) {
    filteredProducts = filteredProducts.filter(products => products.category?.id === selectedCategoryId);
  }

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  return (
    <>
      <NavigationBar />

      <Section className='content-bg text--center'>
        <h1 className='header-small'>Our products</h1>
        <p className='text-large'>Our range of tofu, noodles, dumplings and soy products are made using the highest quality ingredients.</p>
      </Section>

      <ul className={styles['categories-list']}>
        <li>
          <button
            className={selectedCategoryId === null ? `${styles['categories-list__button']} ${styles['categories-list__button--selected']}` : styles['categories-list__button']}
            onClick={() => setSelectedCategoryId(null)}>
            All
          </button>
        </li>
        {productCategories?.map(category => (
          <li key={category.id}>
            <button
              className={selectedCategoryId === category.id ? `${styles['categories-list__button']} ${styles['categories-list__button--selected']}` : styles['categories-list__button']}
              onClick={() => setSelectedCategoryId(category.id)}>
              {category.name}
            </button>
          </li>
        ))}
      </ul>

      <Section>
        <ul className={styles['products-list']}>
          {filteredProducts?.map(product => (
            <li key={product.id}>
              <a href={`/products/${product.id}`}>
                <img
                  className={styles['products-list__image']}
                  src={ASSETS_URL + product.feature_image}
                  alt={product.name} />
              </a>
              <div>
                <div className={styles['products-list__text']}>{product.name}</div>
                {
                  product.nutrition_image &&
                    <button className={styles['products-list__nutrition']} onClick={() => setSelectedProduct(product)}>
                      <FaPlus /> Nutritional Information
                    </button>
                }
              </div>
            </li>
          ))}
        </ul>
      </Section>

      <Section className={styles['locator']}>
        <StoreLocator
          style={{
            position: 'absolute',
            transform: 'translateX(-50%)',
            left: '50%',
            bottom: '30%'
          }} />
      </Section>

      <Footer />

      <NutritionModal
        show={!!selectedProduct}
        closeModal={() => setSelectedProduct(null)}
        product={selectedProduct || undefined} />
    </>
  );
};

export default Products;
