import React from 'react';

import logoImg from 'assets/images/logo.png';
import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className={styles['footer-container']}>
        <div className={`${styles['col']} ${styles['col--50']}`}>
          <h2 className={styles['footer-container__heading']}>Follow</h2>
          <ul className={styles.socials}>
            <li><a>Youtube</a></li>
            <li><a>Instagram</a></li>
            <li><a>Facebook</a></li>
          </ul>

          <img src={logoImg} alt='Evergreen' className={styles.logo} />
        </div>

        <div className={`${styles['col']} ${styles['col--25']}`}>
          <h2 className={styles['footer-container__heading']}>Navigation</h2>
          <ul className={styles['footer-container__list']}>
            <li><a href='/products'>Product range</a></li>
            <li><a href='/recipes'>Recipes</a></li>
            <li><a href='/about'>Our mission</a></li>
            <li><a href='/wholesales'>Wholesales</a></li>
            <li><a href='/contact'>Contact</a></li>
            <li><a href='/privacy'>Privacy Policy</a></li>
          </ul>
        </div>

        <div className={`${styles['col']} ${styles['col--25']}`}>
          <h2 className={styles['footer-container__heading']}>Contact</h2>
          <p>Unigreen Food Pty Ltd, 13-21, Hoskins<br />Ave, Bankstown, NSW, 2200, Australia</p>

          <div className={styles['footer-container__contact-info']}>
            <p>FREE CALL: <a href='tel:180044910'>1800 449 10</a></p>
            <p>TEL: <a href='tel:6197903386'>+61(02) 9790 3386</a></p>
            <p>FAX: <a href='tel:6197905868'>+61(02) 9790 5868</a></p>
          </div>

          <p>
            <strong className={styles['footer-container__subheading']}>For all sales enquiries email</strong>
            <a href='mailto:sales@unigreenfood.com.au'>sales@unigreenfood.com.au</a>
          </p>

          <p>
            <strong className={styles['footer-container__subheading']}>For all customer support enquiries email</strong>
            <a href='mailto:customersupport@unigreenfood.com.au'>customersupport@unigreenfood.com.au</a>
          </p>
        </div>
      </div>

      <div className={styles['footer-copy']}>
        <span>&copy; 2021 Evergreenfood</span>
        <span>Unigreen Food Pty Ltd. Soy Products. Health Foods. Noodle Products. Asian Tofu.</span>
      </div>
    </footer>
  );
};

export default Footer;
