import React from 'react';

import { NavigationBar, Section, Footer, StoreLocatorMap } from 'components';

import styles from './Contact.module.scss';

const Contact: React.FC = () => {
  return (
    <>
      <NavigationBar />

      <Section className={styles['contact']}>
        <h1 className={styles['contact__heading']}>Contact</h1>

        <h2>Get in touch with us</h2>

        <div className='display--flex-wrap'>
          <div className='col--auto'>
            <h3>Sydney</h3>
            <a className='display--block' href='mailto:sales@unigreenfood.com.au'>Sales@unigreenfood.com.au</a>
            <a className='display--block'>13-21 Hoskins Ave, Bankstown NSW 2200</a>
          </div>
          <div className='col--auto'>
            <h3>Melbourne</h3>
            <a className='display--block' href='tel:0212345678'>+613 9574 1109</a>
            <a className='display--block' href='mailto:sales@unigreenfood.com.au'>Sales@unigreenfood.com.au</a>
            <a className='display--block'>Unit 6, 73 Main Road, Clayton South Vic 3169</a>
          </div>
          <div className='col--auto'>
            <h3>Brisbane</h3>
            <a className='display--block' href='mailto:sales@unigreenfood.com.au'>Sales@unigreenfood.com.au</a>
            <a className='display--block'>1 Staple st, Seventeen Mile Rocks 4073 QLD</a>
          </div>
        </div>
      </Section>

      <Section className={styles['location']}>
        <h2 className={styles['location__heading']}>Check stores near you</h2>
        <StoreLocatorMap />
      </Section>

      <Footer />
    </>
  );
};

export default Contact;
